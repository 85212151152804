import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar as BootstrapNavbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { useTranslation } from "next-i18next";
import { User } from "react-feather";

import { useGlobalState } from "../../../context/State";
import routes, { ProtectedRouteCondition, Route } from "../../../routes";
import { isUserSignedIn, signout } from "../../../services/auth";
import Logo, { LogoColors, LogoTextColors } from "../../_atoms/Logo/Logo";

import styles from "./Navbar.module.scss";
import variables from "../../../styles/variables.module.scss";

const Navbar: React.FC = () => {
  const { pathname, reload } = useRouter();
  const [{ user }, { setUser }] = useGlobalState();
  const { t } = useTranslation(["auth", "common"]);

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    (async () => {
      const status = await isUserSignedIn();
      setIsUserAuthenticated(status);
    })();
  });

  const links = [
    {
      route: routes.projects,
      text: t("projectsRouteTitle", { ns: "common" }),
    },
  ];

  const getLinkForAuthStatus = useCallback(
    (route: Route, text: string) => {
      const link = (
        <NavItem className={styles.navlink}>
          <Link href={route.path} passHref={route.path.indexOf("http") > -1}>
            <NavLink active={pathname.indexOf(route.path) > -1}>{text}</NavLink>
          </Link>
        </NavItem>
      );

      if (route.condition === ProtectedRouteCondition.SIGNED_IN) {
        return isUserAuthenticated && link;
      } else {
        return <>{link}</>;
      }
    },
    [isUserAuthenticated, pathname],
  );

  return (
    <BootstrapNavbar
      expand="md"
      className={`justify-content-between navbar-light ${styles.navbar}`}
    >
      <Container className="d-flex justify-content-between">
        <Link href={routes.projects.path}>
          <a className="navbar-brand d-flex align-items-center">
            <Logo
              color={LogoColors.Green}
              textColor={LogoTextColors.Black}
              style={{ width: "9rem" }}
            />
          </a>
        </Link>
        <NavbarToggler onClick={() => setIsNavbarOpen(!isNavbarOpen)} />

        <div className={styles.collapse}>
          <Collapse isOpen={isNavbarOpen} navbar>
            <Nav
              navbar
              className="ml-auto w-100 d-md-flex align-items-md-center justify-content-between justify-content-md-between"
            >
              <ul className={styles.linkList}>
                {links.map((link, i) => (
                  <span key={i}>
                    {getLinkForAuthStatus(link.route, link.text)}
                  </span>
                ))}
              </ul>
              <div className="d-flex align-items-center justify-content-between mx-6 mx-md-0">
                {user ? (
                  <NavItem>
                    <Dropdown
                      direction="start"
                      className="ml-auto mt-auto"
                      toggle={() => setIsDropdownOpen(!isDropdownOpen)}
                      isOpen={isDropdownOpen}
                    >
                      <DropdownToggle className="btn-circle bg-white" caret>
                        <User color={variables.primary} />
                      </DropdownToggle>
                      <DropdownMenu className="ml-auto mt-auto">
                        <DropdownItem>
                          <NavItem>
                            <Link href={routes.preferences.path}>
                              {t("preferencesRouteTitle", { ns: "common" })}
                            </Link>
                          </NavItem>
                        </DropdownItem>
                        <DropdownItem
                          color="primary"
                          onClick={async () => {
                            setUser(null);
                            await signout();
                            reload();
                          }}
                        >
                          {t("signout")}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                ) : (
                  <>
                    <NavItem>
                      <NavLink href={routes.signin.path}>
                        {t("signin.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href={routes.signup.path} className="py-0">
                        <Button color="primary" outline>
                          {t("signup.submit")}
                        </Button>
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </div>
            </Nav>
          </Collapse>
        </div>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
